import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import extraServiceRequest from '@/api/common/extraServiceRequest';
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    components: {
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_1 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/ExpandImageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_2 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/ExpandStorageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_3 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/OpenPort.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_4 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_5 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_6 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_7: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceType_8: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/components/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),

        //=======================================
        extraServiceDetailType_1 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/ExpandImageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading, 
        }),
        extraServiceDetailType_2 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/ExpandStorageThreshold.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_3 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/OpenPort.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_4 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/nvLink.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_5 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_6 : lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_7: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        extraServiceDetailType_8: lazyLoadComponent({
            componentFactory: () => import("@/views/extraServiceRequest/detailComponents/General.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    data() {
        return {
            debounceFnc: null,
            elements: {},
            detailModel: null,
            packageInfoDictionary: {},
            requestParam: {
                filterState : '',
                querySearch: '',
                orderBy: 'CREATED_AT',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 20,
                outRowsNumber: 0,
            },
            isLoadingList: false,
            statusConst: {
                ALL : 0,
                PENDING : 1,
                INPROGRESS : 2,
                APPROVED : 3,
                REJECTED : 4
            },            
            dialogDetailVisible: false,
            detailRequest: null,
            detailComponent: `extraServiceDetailType_1`,
            dialogRejectedVisible: false,
            rejectRequestForm: {
                note: "",
                requestId: "",
                element: null,
                serviceDetailsUpdated : null,
            },
            presetRejectNote: [
                "The port is already in use.",
                "This port is forbidden.",
                "You must be a user prime to make this request",
                "Cannot open more system image", 
                "We like that!",
                "Covid 19 is blocking the service",
            ],
            //Completed dialog            
            dialogCompletedVisible: false,
            completedRequestForm: {
                note: "",
                requestId: "",
                element: null,
                serviceDetailsUpdated : null,
            },
            presetCompletedNote: [
                "Everything is OK",
                "Contact with the customer",
                "Accept to open 1 of the required ports",
                "Business customers", 
                "We like that!",
            ],
            currentTabComponent : "extraServiceType_1"
        };
    },

    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listStaff: state => state.staffs,
            listServicePack: (state) => state.common.listServicePack,
            extraServiceRequestCount: (state) => state.extraServiceRequestCount,
        }),
        isQaOrOwned() {
            return this.rolesInfo.includes('QA') || this.rolesInfo.includes('OWNED')
                || this.userInfo.userEmail.toLowerCase() === 'thuongqth@irender.vn' || this.userInfo.userEmail.toLowerCase() === 'yendph@irender.vn';
        },
    },

    created() {
        this.getData(this.requestParam);
        this.debounceFnc = debounce(() => {
            this.getData(this.requestParam);
        }, 1000);
    },

    methods: {
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.requestParam);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.isLoadingList = true;
            extraServiceRequest.getElementsList(request)
                .then((res) => {
                    try {
                        let responseData = res.data.data;
                        let pagingItem = res.data.data.pagingItem;
                        this.elements = responseData.data;
                        this.requestParam.orderBy = pagingItem.orderBy;
                        this.requestParam.directionSort = pagingItem.directionSort;
                        this.requestParam.pageIndex = pagingItem.pageIndex;
                        this.requestParam.pageSize = pagingItem.pageSize;
                        this.requestParam.outRowsNumber = pagingItem.outRowsNumber;
                    } catch (error) {
                        console.error("getData -> error", error);
                    }
                    setTimeout(() => {
                        this.isLoadingList = false;
                    }, 500);
                })
                .catch(error => {
                    console.error("getData -> error", error);
                    this.isLoadingList = false;
                });
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                orderBy: this.requestParam.orderBy,
                directionSort: this.requestParam.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                outRowsNumber: this.requestParam.outRowsNumber,
            };
            this.getData(request);
        },
        getPackageInfo(elementServicePackage) {
            if (this.packageInfoDictionary.hasOwnProperty(elementServicePackage)) return this.packageInfoDictionary[elementServicePackage];

            let packageInfo = this.listServicePack.find(x => x.id.toLowerCase() === elementServicePackage.toLowerCase());
            if (packageInfo && packageInfo !== null) {
                this.packageInfoDictionary[elementServicePackage] = packageInfo;
                return packageInfo;
            }
            else {
                //console.log("elementServicePackage", elementServicePackage);
                return {
                    name: "---",
                    description: "---",
                    serviceType: 0,
                    numberCard: "---"
                }
            }
        },
        // Pagination page change
        handleCurrentChange(pageIndex) {
            let request = {
                orderBy: this.requestParam.orderBy,
                directionSort: this.requestParam.directionSort,
                pageIndex: pageIndex,
                pageSize: this.requestParam.pageSize,
                outRowsNumber: this.requestParam.outRowsNumber,
            };
            this.getData(request);
        },
        showDetail(element) {
            this.$set(this, "detailRequest", element);
            this.$set(this, "detailComponent", `extraServiceDetailType_${element.extraServiceId}`)
            this.dialogDetailVisible = true;
        },
        showRejectingInput(element) {
            this.rejectRequestForm.reason = "";
            this.rejectRequestForm.requestId = element.id;
            this.rejectRequestForm.element = element;
            this.dialogRejectedVisible = true;
        },        
        showCompletingInput(element) {
            this.currentTabComponent = `extraServiceType_${element.extraServiceId}`;
            this.completedRequestForm.note = "";
            this.completedRequestForm.requestId = element.id;
            this.completedRequestForm.element = element;
            this.dialogCompletedVisible = true;
        },
        changedStatus(targetItem, targetStatus) {
            console.log("this.rejectRequestForm", this.rejectRequestForm);
            console.log("this.completedRequestForm", this.completedRequestForm);
            let data = {};
            switch (targetStatus) {
                case this.statusConst.REJECTED:
                    data = this.rejectRequestForm;
                    break;
                case this.statusConst.APPROVED:
                    data = this.completedRequestForm;
                    break;
            }
            data.deviceInformation = JSON.stringify(
                this.getInformationClient()
            );
            commonAct.showConfirm(`Are you sure perform this action ? 
                    <div><strong>${targetItem.extraServiceName}</strong></div>`, () => {
                    this.showLoading();
                    extraServiceRequest.changedStatus(targetItem.id, targetStatus, data).then(response => {
                    if (response.data.result === 0) {
                        console.log(response.data);
                        targetItem.canSetPending = response.data.data.canSetPending;
                        targetItem.canSetInprogress = response.data.data.canSetInprogress;                        
                        targetItem.canSetApproved = response.data.data.canSetApproved;
                        targetItem.canSetRejected = response.data.data.canSetRejected;

                        targetItem.status = response.data.data.status;
                        targetItem.statusText = response.data.data.statusText;
                        targetItem.statusClass = response.data.data.statusClass;
                        targetItem.statusIcon = response.data.data.statusIcon;

                        this.dialogRejectedVisible = false;
                        this.dialogCompletedVisible = false;
                        this.getData(this.requestParam);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        closeIssues(element) {
            this.$prompt('Please input a note for action close issues', 'Say somethings', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /([a-zA-Z0-9.,\W\w\S\s\D\d]+)/,
                inputErrorMessage: 'Please note something...'
            }).then(({ value }) => {
                this.showLoading();
                extraServiceRequest.closeIssues({ requestId: element.id, noteContent: value }).then(response => {
                    if (response.data.result === 0) {
                        this.getData(this.requestParam);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            }).catch(() => {
                
            });

            //commonAct.showConfirm(`Are you sure perform close this issue ? 
            //        <div><strong>${element.extraServiceName}</strong></div>`, () => {
            //    this.showLoading();
            //    extraServiceRequest.closeIssues(element.id).then(response => {
            //        if (response.data.result === 0) {
            //            this.getData(this.requestParam);
            //        } else {
            //            commonAct.showError(response.data.message);
            //        }
            //        this.hideLoading();
            //    }).catch(error => {
            //        console.error(error);
            //        commonAct.showError(error);
            //        this.hideLoading();
            //    });
            //});
        }
    }
}